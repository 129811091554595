<template>
    <layout class="meeting" @search="pageInit">
        <!-- 筛选tab -->
        <div class="mt20 container filter-wrapper">
            <filter-tab v-if="provinceList.length" title="省份" @clickItem="handleFilterEvent('provinceIndex', $event)"
                :isShowMore="true" :selected="provinceIndex" :list="provinceList">
            </filter-tab>
            <filter-tab v-if="subjectList.length" title="学科" @clickItem="handleFilterEvent('subjectIndex', $event)"
                :isShowMore="true" :selected="subjectIndex" :list="subjectList"></filter-tab>
            <filter-tab title="年份" @clickItem="handleFilterEvent('yearIndex', $event)" :selected="yearIndex"
                :list="yearList"></filter-tab>
            <filter-tab title="月份" @clickItem="handleFilterEvent('monthIndex', $event)" :selected="monthIndex"
                :list="monthList"></filter-tab>
        </div>

        <!-- 筛选条件 -->
        <div class="filter-condition mt20"
            :class="{ 'active': (provinceIndex != -1 || subjectIndex != -1 || yearIndex != -1 || monthIndex != -1 || params.name) }">
            <div class="container flex y-center x-left">
                <div class="search-text"
                    v-if="provinceIndex != -1 || subjectIndex != -1 || yearIndex != -1 || monthIndex != -1 || params.name">搜索条件：</div>
                <a-breadcrumb class="search-breadcrumb">
                    <a-breadcrumb-item v-if="params.name">{{ params.name }}</a-breadcrumb-item>
                    <a-breadcrumb-item v-if="provinceIndex != -1">{{ provinceList[provinceIndex].Name }}</a-breadcrumb-item>
                    <a-breadcrumb-item v-if="subjectIndex != -1">{{ subjectList[subjectIndex].operate_name }}
                    </a-breadcrumb-item>
                    <a-breadcrumb-item v-if="yearIndex != -1">{{ yearList[yearIndex].name }}</a-breadcrumb-item>
                    <a-breadcrumb-item v-if="monthIndex != -1">{{ monthList[monthIndex].name }}</a-breadcrumb-item>
                </a-breadcrumb>
                <a-button class="clear-btn" @click="handleClearEvent"
                    v-if="provinceIndex != -1 || subjectIndex != -1 || yearIndex != -1 || monthIndex != -1 || params.name"
                    style="margin-left:20px;" size="small">清除</a-button>
            </div>
        </div>

        <!-- 会议列表 -->
        <div class="container meeting-box flex mt30">
            <div class="meeting-list">
                <a-spin :spinning="loading" tip="Loading...">
                    <div class="meeting-item" :key="index" v-for="(item, index) in meetingList"
                        @click="meetJumpDetail(item.href, item.id)">
                        <across-media-item :item="item">
                            <template v-slot:desc>
                                <span class="meeting-item-address">
                                    <span class="color999 fs14"><i
                                        class="med med-31shijian"></i>{{ item.start_time.substr(0, 10) }}</span>
                                    <span class="color999 ml20 fs14"><i
                                            class="med med--didian"></i>{{ item.address }}</span>
                                </span>
                            </template>
                            <template v-slot:bottom>
                                <div class="flex x-left flex-wrap meeting-item-link">
                                    <a v-if="item.is_open_live == 1" class="btn-link btn-link-7"
                                        @click.stop="goRecordInfoPage('live', item)">观看直播</a>
                                    <a v-if="item.is_open_live == 2" class="btn-link btn-link-7"
                                        @click.stop="goRecordInfoPage('live_appoint', item)">
                                        {{ item.is_appointment == 1 ? '已预约直播' : '预约直播' }}
                                    </a>
                                    <a v-if="item.is_open_program_duty == 1" class="btn-link btn-link-8"
                                        @click.stop="goRecordInfoPage('academic', item)">学术任务</a>
                                    <a v-if="item.is_statement == 1" class="btn-link btn-link-6"
                                        @click.stop="goRecordInfoPage('Undertaking', item)">防疫承诺书</a>
                                    <a v-if="item.is_invoice == 1" class="btn-link btn-link-5"
                                        @click.stop="goRecordInfoPage('invoice', item)">电子发票</a>
                                    <!-- <a v-if="item.open_search_cert == 1" class="btn-link">学分下载</a> -->
                                    <a v-if="item.is_open_meeting_video == 1" class="btn-link btn-link-4"
                                        @click.stop="goRecordInfoPage('video', item)">视频回放</a>
                                    <!-- 参会注册和签到票暂未用到这个参数 && item.is_meeting_reg == 0-->
                                    <a v-if="item.is_open_meeting_reg == 1" class="btn-link btn-link-3"
                                        @click.stop="goRecordInfoPage('reg', item)">参会注册</a>
                                    <a v-if="item.is_open_meeting_reg == 1 && item.is_meeting_reg_type == 1"
                                        class="btn-link btn-link-2" @click.stop="goRecordInfoPage('signIn', item)">签到票</a>
                                    <a v-if="item.is_open_abstract == 1" type="2" class="btn-link  btn-link-1"
                                        @click.stop="goRecordInfoPage('paper', item)">论文投稿</a>
                                    <a class="btn-link btn-link-0" @click.stop="goRecordInfoPage('person', item)">个人中心</a>
                                </div>
                            </template>
                        </across-media-item>
                    </div>
                    <a-empty v-if="!meetingList.length && !loading" />
                </a-spin>
                <div class="t-c" v-if="meetTotal > 0">
                    <a-pagination :total="meetTotal" v-model:current="params.page" :page-size="params.pageSize"
                        show-quick-jumper @change="handlePageChangeEvent" :hideOnSinglePage="true" />
                </div>
            </div>
            <div class="meeting-right">
                <a href="javascript:;">
                    <img src="~@/assets/images/adv-banner.png" alt="">
                </a>
                <div style="margin-top:60px;">
                    <layout-right :config="layoutRightConfig"></layout-right>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import layout from '@/components/layout/index';
import layoutRight from '@/components/layout/layout-right';
import commonTitle from '@/components/common-title';
import filterTab from '@/components/filter-tab';
import acrossMediaItem from '@/components/across-media-item';
import meetingItem from '@/components/meeting-item';
import { jsonFormat } from '@/utils/jsonFormat';
import { meetJumpDetail, goRecordInfoPage } from '@/utils/jumpPageMethods';
import { mapState } from 'vuex';
import storage from 'store';
let layoutRightConfig = {
    // 新闻
    news: {
        show: true, // 是否显示
        methods: 'post',
        title: '新闻资讯', // 标题
        data: {
            page: 1,
            limit: 5,
            type: 2,
        },   // 参数条件
    },
    // 直播
    correlationLive: { //相关直播
        show: true, // 是否显示
        methods: 'get',
        title: '相关直播', // 标题
        data: {
            tj_token: storage.get('tid'),
            page: 1,
            limit: 3,
            subject_ids: storage.get('subjectIds') || ''
        },   // 参数条件
    },
    // 推荐视频
    video: {
        show: true, // 是否显示
        methods: 'get',
        title: '最新回放', // 标题
        data: {
            page: 1,
            PageSize: 4,
        },   // 参数条件
    },
}
export default {
    name: 'MeetingList',
    components: {
        layout,
        commonTitle,
        filterTab,
        meetingItem,
        acrossMediaItem,
        layoutRight
    },
    data() {
        return {
            loading: true,
            provinceIndex: -1,
            subjectIndex: -1,
            yearIndex: -1,
            monthIndex: -1,
            // 会议列表请求参数
            params: {
                page: 1,
                pageSize: 10,
            },
            provinceList: [],
            subjectList: [],
            yearList: [],
            monthList: [],
            meetTotal: 0,
            // 会议列表
            meetingList: [],
            layoutRightConfig:{},
        }
    },
    computed: {
        ...mapState(['userInfo'])
    },
    methods: {
        meetJumpDetail,
        goRecordInfoPage,
        // 清除事件
        handleClearEvent() {
            this.provinceIndex = -1
            this.subjectIndex = -1
            this.yearIndex = -1
            this.monthIndex = -1
            this.params.name = ''
            if (this.$route.query.search_name) {
                this.$router.replace({
                    path: '/meeting-list'
                })
            }

            this.handleFilterEvent()
        },
        // 获取省份
        getRegion() {
            this.request.get('GetRegion').then(res => {
                let regionObj = {
                    name: 'Name',
                    id: 'ID'
                }
                this.provinceList = jsonFormat(res.data, regionObj)
            })
        },
        // 获取全部学科
        getSubject() {
            this.$store.dispatch('getSubjectList').then(res => {
                let subjectList = [];
                res.data.forEach((item,index) => {
                    item.name = item.operate_name;
                    if(this.__subject_id == item.id){
                        this.subjectIndex = index;
                    }
                    subjectList.push(item);
                })
                this.subjectList = subjectList;

                // 拿到学科后触发
                this.handleFilterEvent();
            })
        },
        // 获取年份
        getYearList() {
            let yearList = [];
            let currYear = (new Date()).getFullYear();
            // 加上过年的
            let yearLen = currYear + 1;
            for (let i = yearLen; i >= 2018; i--) {
                yearList.push({ id: i, name: i + '年' });
            }
            this.yearList = yearList;
        },
        // 获取月份
        getMonthList() {
            let monthList = [];
            for (let i = 1; i <= 12; i++) {
                monthList.push({ id: i, name: i + '月' });
            }
            this.monthList = monthList;
        },
        // 获取会议列表
        getMeetingList() {
            this.loading = true
            this.request.get('GetMeetingList', this.params).then(res => {
                let meetObj = {
                    title: 'cn_title',
                    img: 'pic_url'
                }
                this.meetTotal = Number(res.data.count)
                this.meetingList = jsonFormat(res.data.data, meetObj)
                this.loading = false
            })
        },
        // 筛选切换事件
        handleFilterEvent(key, e) {
                       
            this.params.page = 1
            key && (this[key] = e)

            // 用户url学科或者已选学科
            let subject_ids = this.$route.query.subject_id;
            let operate_subject = subject_ids;
            // 切换后的学科
            if(this.subjectIndex != -1){
                subject_ids = this.subjectList[this.subjectIndex].subject_ids;
                operate_subject = this.subjectList[this.subjectIndex].id;
            }

            if (this.provinceIndex != -1) {
                this.params.province = this.provinceList[this.provinceIndex].id
            } else {
                delete this.params.province
            }
            if (this.subjectIndex != -1) {
                this.params.subject_ids = subject_ids
            } else {
                delete this.params.subject_ids
            }
            if (this.yearIndex != -1) {
                this.params.year = this.yearList[this.yearIndex].name.split("年")[0]
            } else {
                delete this.params.year
            }
            if (this.monthIndex != -1) {
                this.params.month = this.monthList[this.monthIndex].name.split("月")[0]
            } else {
                delete this.params.month
            }
            let { search_name } = this.$route.query;
            if (search_name) {
                this.params.name = search_name
            } else {
                delete this.params.name
            } 

            // 右侧数据调整  更新右侧数据
            layoutRightConfig.news.data.operate_id = operate_subject;
            layoutRightConfig.video.data.subject_ids = subject_ids;
            layoutRightConfig.correlationLive.data.subject_ids = subject_ids;

            this.layoutRightConfig = JSON.parse(JSON.stringify(layoutRightConfig));

            // 获取会议列表
            this.getMeetingList()

        },
        // 切换页码
        handlePageChangeEvent(page) {
            window.scrollTo(0,0);
            this.params.page = page
            this.getMeetingList()
        },
        pageInit() {
            let { month, year,subject_id } = this.$route.query;
            // 如果url不存在则取用户自己关注的学科
            subject_id = subject_id || this.userInfo.operate_subject;
            this.__subject_id = subject_id;
            this.params.subject_ids = subject_id;
            // 年回显
            year = year || new Date().getFullYear();
            this.params.year = year;
            this.yearList.forEach((item, index) => {
                if (item.id == year) {
                    this.yearIndex = index;
                }
            })
            // 月回显
            if (month) {
                this.monthIndex = month - 1;
                this.params.month = month;
            } 
            // 默认本月
            // else {
            //     this.monthList.forEach((item, index) => {
            //         if (item.id == new Date().getMonth()+1) {
            //             this.monthIndex = index;
            //         }
            //     })
            // }
        },
    },
    created() {
        this.getRegion();
        this.getSubject();
        this.getYearList();
        this.getMonthList();
    },

    // 缓存页面二次进入的钩子函数
    activated() {
        this.pageInit();
    },
}
</script>
<style lang="less" scoped>
.meeting {
    .filter-box:not(:last-child) {
        border-bottom: 0;
    }
}

.filter-condition {
    background: #f2f4fa;
    overflow: hidden;
    height: 8px;

    &.active {
        height: 50px;
        line-height: 50px;
    }

    /deep/.ant-breadcrumb>span:last-child {
        color: inherit;
    }
}

.meeting-list {
    width: calc(66.66% - 30px);
    min-height: 600px;
    margin-right: 30px;

    .meeting-item {
        margin-bottom: 20px;
        border-bottom: 1px dashed #d8d8d8;

        &:last-child {
            border: 0;
        }
    }

    .btn-link {
        color: #999999;
        font-size: 12px;
        padding: 4px 20px;
        border: 1px solid #f9f9f9;
        // background: #f9f9f9;
        border-radius: 3px;
        margin-top: 5px;
        margin-right: 5px;
        flex-shrink: 0;

        &:hover {
            background: linear-gradient(to right, #5f96e6, #1e51c9);
            color: #fff;
        }
    }
    .btn-link-0 {
        border-color: #3e7eaf;
        color: #3e7eaf;
        &:hover {
            background: #3e7eaf;
        }
    }
    .btn-link-1 {
        border-color: #8e35a9;
        color: #8e35a9;
        &:hover {
            background: #8e35a9;
        }
    }
    .btn-link-2 {
        border-color: #488c5f;
        color: #488c5f;
        &:hover {
            background: #488c5f;
        }
    }
    .btn-link-3 {
        border-color: #eea952;
        color: #eea952;
        &:hover {
            background: #eea952;
        }
    }
    .btn-link-4 {
        border-color: #5461c9;
        color: #5461c9;
        &:hover {
            background: #5461c9;
        }
    }
    .btn-link-5 {
        border-color: #709b1d;
        color: #709b1d;
        &:hover {
            background: #709b1d;
        }
    }
    .btn-link-6 {
        border-color: #a93553;
        color: #a93553;
        &:hover {
            background: #a93553;
        }
    }
    .btn-link-7 {
        border-color: #fc7647;
        color: #fc7647;
        &:hover {
            background: #fc7647;
        }
    }
    .btn-link-8 {
        border-color: #f7cf46;
        color: #f7cf46;
        &:hover {
            background: #f7cf46;
        }
    }
}

.meeting-right {
    width: 33.33%;
}

@media screen and (max-width:768px) {
    .filter-wrapper {
        margin-top: 0;
        position: fixed;
        background: #fff;
        z-index: 99999;
        padding:0;
        margin-top:0;
        /deep/.filter-box{
            border-bottom:1px solid #f5f5f5!important;
            &:last-child{
                border-bottom:0!important;
        }
            span,a{
                padding:5px 10px;
                line-height: 1;
            }
            .overflow-hidden{
                height:30px;
            }
        }
    }

    .filter-condition {
        margin-top: 185px;
        &.active{
            min-height:0.8rem;
            padding:5px 0;
            height:auto;
            line-height: 1;
            display: flex;
            align-items: center;
        }

        .container {
            margin-top: 0;
            padding: 0 10px;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            .search-text {
                width: 75px;
            }

            .search-breadcrumb {
                display: inline;
                text-align: left;
            }

            .clear-btn {
                width: 48px;
                margin-left:5px!important;
            }
        }
    }

    .meeting-box {
        margin-top: 20px;
        display: block;
        padding: 0 10px;

        .meeting-list {
            width: 100%;
            min-height: 0;
            .btn-link{
                margin-top: 0;
            }
            .meeting-item {
                &-address {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box !important;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                }
                &-link{
                    overflow-x: scroll;
                    flex-wrap: nowrap;
                }
            }
        }

        .meeting-right {
            width: 100%;
            margin-top: 20px;
            img {
                width: 100%;
            }
        }
    }
}
</style>
